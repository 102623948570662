<template>
    <b-overlay :show="loading" rounded="sm">
        <div>
            <b-row>
                <b-col cols="12">
                    <b-card class="mb-4" no-body>
                        <div class="d-flex justify-content-center" v-if="company && company.logoURL">
                            <img
                                :src="company.logoURL"
                                alt="Builders Logo"
                                style="max-width: 400px; max-height: 300px"
                            />
                        </div>
                        <hr class="m-0" />
                        <b-form class="container mx-auto" @submit.prevent="saveData">
                            <b-card-body class="p-0 pt-5">
                                <div class="p-4 bg-light">
                                    <h3 class="mb-4">Loan Calculator</h3>
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <label class="fw-medium mb-1" for="type">Loan Type</label>
                                            <b-form-input readonly value="Personal"></b-form-input>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <label class="fw-medium mb-1" for="period">Period in Months</label>
                                                <div
                                                    style="width: 30px"
                                                    class="d-flex justify-content-end align-items-center"
                                                    v-if="loadingPeriods"
                                                >
                                                    <b-spinner small label="Small Spinner"></b-spinner>
                                                </div>
                                            </div>
                                            <b-form-select
                                                v-model="selectedPeriod"
                                                :options="periods"
                                                :disabled="periods.length <= 0"
                                                @change="getMonthlyPayments"
                                                required
                                            ></b-form-select>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <label class="fw-medium mb-1" for="period">Loan Amount</label>
                                            <b-form-input
                                                id="period"
                                                aria-describedby="period-feedback"
                                                type="number"
                                                v-model="amount"
                                                :state="periodState"
                                                min="2500"
                                                max="20000"
                                                required
                                                @blur="getMonths"
                                            ></b-form-input>
                                            <b-form-invalid-feedback id="period-feedback">
                                                Min value {{ min }} - Max value {{ max }}
                                            </b-form-invalid-feedback>
                                        </div>
                                        <div class="col-12 col-sm-6 col-md-3">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <label class="fw-medium mb-1" for="period">Monthly Payments</label>
                                                <div
                                                    style="width: 30px"
                                                    class="d-flex justify-content-end align-items-center"
                                                    v-if="loadingPayment"
                                                >
                                                    <b-spinner small label="Small Spinner"></b-spinner>
                                                </div>
                                            </div>
                                            <b-form-input v-model="monthly" readonly></b-form-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-4">
                                    <h3 class="mb-3">Customer Information</h3>
                                    <b-row class="mb-4">
                                        <b-col sm="6" class="">
                                            <label class="fw-medium mb-1" for="name">First Name</label>
                                            <b-form-input
                                                v-model="firstName"
                                                id="name"
                                                type="text"
                                                placeholder="Your name here"
                                                required
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="6" class="">
                                            <label class="fw-medium mb-1" for="lastName">Last Name</label>
                                            <b-form-input
                                                v-model="lastName"
                                                id="lastName"
                                                type="text"
                                                placeholder="Your last name here"
                                                required
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-4">
                                        <b-col sm="6" class="">
                                            <label class="fw-medium mb-1" for="employer">Employer Name</label>
                                            <b-form-input
                                                v-model="employerName"
                                                id="employer"
                                                type="text"
                                                placeholder="Employer name here"
                                                required
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="6" class="">
                                            <label class="fw-medium mb-1" for="email">Email Address</label>
                                            <b-form-input
                                                v-model="email"
                                                id="email"
                                                type="email"
                                                placeholder="Email address here"
                                                required
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-4">
                                        <b-col sm="6" class="">
                                            <label class="fw-medium mb-1" for="phone">Phone Number</label>
                                            <b-form-input
                                                v-model="phone"
                                                id="phone"
                                                type="text"
                                                placeholder="Phone Number here"
                                                required
                                            ></b-form-input>
                                        </b-col>
                                        <b-col sm="6" class="">
                                            <label class="fw-medium mb-1" for="address">Address</label>
                                            <b-form-input
                                                v-model="address"
                                                id="address"
                                                type="text"
                                                placeholder="Address here"
                                                required
                                            ></b-form-input>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="p-4 bg-light">
                                    <h3 class="mb-3">Customer Documents</h3>
                                    <b-row class="mb-4">
                                        <b-col sm="6">
                                            <label class="fw-medium mb-1" for="title4">Valid ID*</label>
                                            <b-form-file
                                                accept=".jpg, .jpeg, .png, .pdf, .docx"
                                                v-model="id"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                                required
                                            ></b-form-file>
                                        </b-col>
                                        <b-col sm="6">
                                            <label class="fw-medium mb-1" for="title4">Payslip #1</label>
                                            <b-form-file
                                                accept=".jpg, .jpeg, .png, .pdf, .docx"
                                                v-model="payslip1"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mb-4">
                                        <b-col sm="6">
                                            <label class="fw-medium mb-1" for="title4">Payslip #2</label>
                                            <b-form-file
                                                accept=".jpg, .jpeg, .png, .pdf, .docx"
                                                v-model="payslip2"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </b-col>
                                        <b-col sm="6">
                                            <label class="fw-medium mb-1" for="title4">Quote (If Applicable)</label>
                                            <b-form-file
                                                accept=".jpg, .jpeg, .png, .pdf, .docx"
                                                v-model="other"
                                                placeholder="Choose a file or drop it here..."
                                                drop-placeholder="Drop file here..."
                                            ></b-form-file>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card-body>
                            <div class="p-35 border-top">
                                <div class="btn-grp text-right">
                                    <b-button
                                        :disabled="loadingSave || loadingPeriods || loadingPayment"
                                        variant="primary"
                                        class="px-4"
                                        type="submit"
                                    >
                                        <b-spinner small v-if="loadingSave"></b-spinner>
                                        <span v-else>Send</span>
                                    </b-button>
                                </div>
                            </div>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </b-overlay>
</template>

<script>
export default {
    name: 'Customer',
    data: () => ({
        loading: true,
        options: [{ text: 'Personal', value: 1 }],
        periods: [],
        selectedType: 1,
        selectedPeriod: null,
        amount: null,
        monthly: null,
        loadingSave: false,
        loadingPeriods: false,
        loadingPayment: false,

        firstName: '',
        lastName: '',
        employerName: '',
        email: '',
        phone: '',
        address: '',
        id: null,
        payslip1: null,
        payslip2: null,
        other: null,
        min: 2500,
        max: 20000,
        company: null,
    }),
    methods: {
        async getMonths() {
            if (this.periodState && this.amount != null) {
                this.loadingPeriods = true;
                this.periods = await this.$store.dispatch('loan/applications/get_monthsByLoanType', {
                    amount: this.amount,
                });
                this.loadingPeriods = false;
                this.getMonthlyPayments();
            }
        },
        async getMonthlyPayments() {
            if (this.periodState && this.selectedPeriod != null) {
                this.loadingPayment = true;
                const res = await this.$store.dispatch('loan/applications/get_monthlyPayment', {
                    amount: this.amount,
                    months: this.selectedPeriod,
                });
                this.monthly = res.amount;
                this.loadingPayment = false;
            }
        },
        async saveData() {
            this.loadingSave = true;
            let data = {
                amount: this.amount,
                periodInMonths: this.selectedPeriod,
                monthlyPayment: this.monthly,
                firstName: this.firstName,
                lastName: this.lastName,
                employerName: this.employerName,
                email: this.email,
                phone: this.phone,
                address: this.address,
                id: this.id,
                payslip1: this.payslip1,
                quote: this.payslip2,
                other: this.other,
            };
            await this.$store.dispatch('loan/applications/add_builders', data);
            this.clearValues();
            this.loadingSave = false;
        },
        clearValues() {
            this.firstName = '';
            this.lastName = '';
            this.employerName = '';
            this.email = '';
            this.phone = '';
            this.address = '';
            this.id = null;
            this.payslip1 = null;
            this.payslip2 = null;
            this.other = null;
            this.monthly = null;
            this.amount = null;
            this.selectedPeriod = null;
        },
        async getData() {
            this.loading = true;
            this.company = await this.$store.dispatch('loan/applications/get_companyInformation');
            const res = await this.$store.dispatch('loan/applications/get_companyLoanRange');
            this.min = res.minAmount;
            this.max = res.maxAmount;
            this.loading = false;
        },
    },
    computed: {
        periodState() {
            return (this.amount >= this.min && this.amount <= this.max) || this.amount == null;
        },
    },
    created() {
        this.getData();
    },
};
</script>
